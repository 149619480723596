function NoNavContainer({ children }) {
  return (
    <div className="container">
      <h1 className="py-5 text-center">
        <img
          src={`${process.env.PUBLIC_URL}/images/logo.png`}
          width="30"
          alt="Infinite Catalog"
          style={{ verticalAlign: "baseline" }}
        />
        <a href={process.env.REACT_APP_SALES_ROOT} className="ml-3">
          Infinite Catalog
        </a>
      </h1>
      {children}
    </div>
  );
}

export default NoNavContainer;
