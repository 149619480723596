import NoNavContainer from "../components/NoNavContainer";

function Maintenance() {
  return (
    <NoNavContainer>
      <h2>We'll be back soon!</h2>
      <p>
        Sorry for the inconvenience but we&rsquo;re performing some maintenance
        at the moment. If you need to you can always{" "}
        <a href="mailto:support@infinitecatalog.com">contact us</a>, otherwise
        we&rsquo;ll be back online shortly!
      </p>
    </NoNavContainer>
  );
}
export default Maintenance;
